const Trophy = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="35" height="34" viewBox="0 0 31 30" fill="none">
            <path d="M3 5.21534C3 10.4909 5.51789 13.7737 10.5792 15.3321C11.2439 16.1753 11.9979 16.8395 12.7137 17.3121V22.8048H11.1288C9.32667 22.8048 8.39364 23.8394 8.39364 25.5384V28.0293C8.39364 28.6041 8.84099 29 9.3778 29H21.6221C22.159 29 22.6063 28.6041 22.6063 28.0293V25.5384C22.6063 23.8394 21.6605 22.8048 19.8584 22.8048H18.2863V17.3249C19.0021 16.8522 19.7689 16.1753 20.4335 15.3321C25.482 13.761 28 10.4909 28 5.21534C28 3.89965 27.182 3.09489 25.8143 3.09489H23.3348C23.1304 1.79197 22.21 1 20.6636 1H10.3364C8.80264 1 7.86962 1.7792 7.66512 3.09489H5.18558C3.81799 3.09489 3 3.89965 3 5.21534ZM4.8277 5.39418C4.8277 5.18978 4.98108 5.02373 5.19836 5.02373H7.60121V7.52738C7.60121 9.48178 8.11246 11.2956 8.9049 12.854C6.23363 11.5128 4.8277 9.03469 4.8277 5.39418ZM9.53117 7.88505V3.73358C9.53117 3.26096 9.86348 2.92884 10.3364 2.92884H20.6636C21.1364 2.92884 21.4687 3.26096 21.4687 3.73358V7.88505C21.4687 12.4325 17.2766 16.5074 15.5 16.5074C13.7234 16.5074 9.53117 12.4325 9.53117 7.88505ZM10.3236 27.0712V25.5384C10.3236 25.053 10.6431 24.7336 11.1288 24.7336H19.8584C20.3568 24.7336 20.6764 25.053 20.6764 25.5384V27.0712H10.3236ZM14.6436 22.8048V18.2957C14.9504 18.3851 15.2316 18.4234 15.5 18.4234C15.7684 18.4234 16.0496 18.3851 16.3563 18.2957V22.8048H14.6436ZM22.095 12.854C22.8875 11.2956 23.3987 9.48178 23.3987 7.52738V5.02373H25.8016C26.0189 5.02373 26.1723 5.18978 26.1723 5.39418C26.1723 9.03469 24.7664 11.5128 22.095 12.854Z"
                  fill="#E6BB3D"/>
            <path opacity="0.2"
                  d="M3 5.21534C3 10.4909 5.51789 13.7737 10.5792 15.3321C11.2439 16.1753 11.9979 16.8395 12.7137 17.3121V22.8048H11.1288C9.32667 22.8048 8.39364 23.8394 8.39364 25.5384V28.0293C8.39364 28.6041 8.84099 29 9.3778 29H21.6221C22.159 29 22.6063 28.6041 22.6063 28.0293V25.5384C22.6063 23.8394 21.6605 22.8048 19.8584 22.8048H18.2863V17.3249C19.0021 16.8522 19.7689 16.1753 20.4335 15.3321C25.482 13.761 28 10.4909 28 5.21534C28 3.89965 27.182 3.09489 25.8143 3.09489H23.3348C23.1304 1.79197 22.21 1 20.6636 1H10.3364C8.80264 1 7.86962 1.7792 7.66512 3.09489H5.18558C3.81799 3.09489 3 3.89965 3 5.21534Z"
                  fill="#E6BB3D"/>
        </svg>
    );
}

export default Trophy;
